export function imageSwitch() {
  // let oldText = "Other"
  // let text = oldText.replace("Other", " ");

  let elem = document.getElementsByClassName('js_switchimage'),
  sp = '_sp',
  pc = '_pc',
  replaceWidth = 768;
  if (elem) {
    if (window.innerWidth >= replaceWidth) {
      for (let index = 0; index < elem.length; index++) {
        let imagesrc = elem[index].getAttribute("src");
        let changeSource = imagesrc.replace('sp','pc');
        elem[index].setAttribute('src',changeSource);
      }
    }
  }
}
