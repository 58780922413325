// load sass
import '../sass/common.scss'; //scssの読み込み
// modernizr // webp
import {modernizer}  from "./common/modernizr.js";
modernizer();
// load imageSwitch
import {imageSwitch} from "./common/sub_imageswtch.js";
imageSwitch();

window.addEventListener('load', () => {
  document.getElementById('hotel-spiral-resort').classList.add('active')
})

if (document.getElementsByClassName('smart-hamburger')[0] !=null) {
  document.getElementsByClassName('smart-hamburger')[0].onclick = () => {
    // document.getElementsByClassName('smart-hamburger')[0].classList.toggle('active')
    // document.getElementById('global-content').classList.toggle('standby')

    if (document.getElementsByClassName('smart-hamburger')[0].classList.contains('active') == false) {
      document.getElementsByClassName('smart-hamburger')[0].classList.add('active')
    } else {
      document.getElementsByClassName('smart-hamburger')[0].classList.remove('active')
    }

    if (document.getElementById('global-content').classList.contains('standby') == false) {
      document.getElementById('global-content').classList.add('standby')
      setTimeout(() => {
        // body active
        document.getElementById('global-content').classList.add('active')
      }, 100);

    } else {
      //deactive body.
      document.getElementById('global-content').classList.remove('active')
      setTimeout(() => {
        document.getElementById('global-content').classList.remove('standby')
      }, 800);
    }
  } // onclick
}

